import { Show, splitProps } from 'solid-js';
import { twMerge } from '@troon/tailwind-preset/merge';
import type { JSX } from 'solid-js';

type Props = Omit<JSX.SVGElementTags['svg'], 'width' | 'height' | 'title'> & {
	title?: string;
	height?: string | number | null;
	width?: string | number | null;
};
export type IconProps = Omit<Props, 'children'>;

export function Icon(props: Props) {
	const [, svgProps] = splitProps(props, ['title']);

	return (
		<svg
			viewBox="0 0 24 24"
			fill="none"
			stroke="none"
			{...svgProps}
			height={svgProps.height === null ? undefined : (svgProps.height ?? '1.2em')}
			width={svgProps.width === null ? undefined : (svgProps.width ?? '1.2em')}
			class={twMerge('inline-flex', svgProps.class)}
			aria-hidden={props.title ? 'false' : 'true'}
			xmlns="http://www.w3.org/2000/svg"
		>
			<Show when={props.title}>
				<title>{props.title}</title>
			</Show>
			{props.children}
		</svg>
	);
}
