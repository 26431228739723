import { createContext, createEffect, createSignal, splitProps, createUniqueId } from 'solid-js';
import { useLocation, useNavigate, useSubmission } from '@solidjs/router';
import { twMerge } from '@troon/tailwind-preset/merge';
import { mergeRefs } from '@solid-primitives/refs';
import type { OperationResult, TypedDocumentNode } from '@urql/core';
import type { Action } from '@solidjs/router';
import type { Accessor, JSX } from 'solid-js';

type Rec = Record<string, unknown>;
type Mutation = { __typename?: string; [key: string]: unknown };

type BaseProps = Omit<JSX.FormHTMLAttributes<HTMLFormElement>, 'onSubmit'> & {
	suppressRequired?: boolean;
	id?: string;
};

type GraphQLProps<R extends Rec = Rec, V extends Rec = Rec, M extends Mutation = Mutation> = BaseProps & {
	action: Action<[FormData], OperationResult<M, R> | void>;
	document: TypedDocumentNode<M, V>;
};

type RestProps<R extends Rec = Rec> = BaseProps & {
	action: Action<[FormData], R>;
	document?: never;
};

type Props<R extends Rec, V extends Rec> = GraphQLProps<R, V> | RestProps<R>;

export function Form<R extends Rec, V extends Rec>(props: Props<R, V>) {
	const [form, setForm] = createSignal<HTMLFormElement>();
	const navigate = useNavigate();
	const location = useLocation();

	const required =
		// eslint-disable-next-line solid/reactivity
		props.suppressRequired || !props.document
			? {}
			: // eslint-disable-next-line solid/reactivity
				props.document?.definitions.reduce((memo: Record<string, boolean>, defs) => {
					if (defs.kind !== 'OperationDefinition' || !defs.variableDefinitions) {
						return memo;
					}
					for (const def of defs.variableDefinitions) {
						memo[def.variable.name.value] = def.type.kind === 'NonNullType';
					}
					return memo;
				}, {});

	const [, formAttrs] = splitProps(props, ['document', 'suppressRequired']);
	const [fieldErrors, setFieldErrors] = createSignal<Record<string, Array<string>>>({});
	const [errors, setErrors] = createSignal<Array<string>>([]);
	const uniqueId = createUniqueId();

	const data = useSubmission(
		// eslint-disable-next-line solid/reactivity
		props.action as Action<[FormData], Rec>,
		([data]) => data.get('__formId') === (props.id ?? uniqueId),
	);

	createEffect(() => {
		if (!data.result?.error && !data.error) {
			setFieldErrors({});
			setErrors([]);
			return;
		}

		if (data.error?.statusCode === 401 && location.pathname !== '/auth/login') {
			const params = new URLSearchParams({ redirect: `${location.pathname}${location.search}` });
			navigate(`/auth/login?${params.toString()}`, { state: { errorMessage: data.error.message } });
			return;
		}

		let newErrors: Record<string, Array<string>> = {};

		const error = data.error?.error as
			| undefined
			| { code: number; message: string; fields: Record<string, Array<string>> }
			| { displayMessage: string; fields: Array<{ displayMessage: string; field: string }> };
		if (error && ('message' in error || 'displayMessage' in error)) {
			setErrors(['message' in error ? error.message : error.displayMessage!]);
		}

		if (error?.fields) {
			newErrors = (error.fields as unknown as Array<{ displayMessage: string; field: string }>).reduce(
				(memo, { field, displayMessage }) => {
					if (!(field in memo)) {
						memo[field] = [];
					}
					memo[field]!.push(displayMessage);
					return memo;
				},
				{} as Record<string, Array<string>>,
			);
		}

		// @ts-ignore TODO: narrowing between graphql and rest is rough
		for (const err of data.result?.error?.graphQLErrors ?? []) {
			if (typeof err.extensions.displayMessage === 'string') {
				setErrors((errs) => [...errs, err.extensions.displayMessage] as Array<string>);
			} else if ((err.extensions.fields as Array<unknown>)?.length === 0) {
				setErrors((errs) => [...errs, 'There was an error completing your request. Please try again.']);
			}

			for (const fieldErr of (err.extensions.fields ?? []) as Array<{ field: string; displayMessage: string }>) {
				if (!(fieldErr.field in newErrors)) {
					newErrors[fieldErr.field] = [];
				}
				newErrors[fieldErr.field]!.push(fieldErr.displayMessage);
			}
		}
		setFieldErrors(newErrors);

		(form()?.querySelector('[aria-invalid]') as HTMLInputElement)?.focus();
	});

	return (
		<form
			{...formAttrs}
			method="post"
			ref={mergeRefs(props.ref, setForm)}
			class={twMerge('flex flex-col gap-y-6', formAttrs.class)}
		>
			<input type="hidden" name="__formId" value={props.id ?? uniqueId} />
			<FormContext.Provider value={{ data, errors, fieldErrors, required }}>{props.children}</FormContext.Provider>
		</form>
	);
}

type CTX = {
	data: ReturnType<typeof useSubmission>;
	errors: Accessor<Array<string>>;
	fieldErrors: Accessor<Record<string, Array<string>>>;
	required: Record<string, boolean>;
};

export const FormContext = createContext<CTX>({
	data: { pending: false, url: '', clear: () => {}, retry: () => {}, input: [], error: null },
	errors: () => [],
	fieldErrors: () => ({}),
	required: {},
});

export function disableAutocomplete(opts: {
	autocomplete?: JSX.InputHTMLAttributes<HTMLInputElement>['autocomplete'] | undefined;
	[key: string]: unknown;
}) {
	return {
		autocomplete: opts.autocomplete,
		'data-1p-ignore': opts.autocomplete === 'off' ? true : undefined,
		'data-lpignore': opts.autocomplete === 'off' ? 'true' : undefined,
		'data-form-type': opts.autocomplete === 'off' ? 'other' : undefined,
		'data-bwignore': opts.autocomplete === 'off' ? true : undefined,
	} as const;
}
